/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Autoplay } from 'swiper/modules'

import { path, useApp, useWidth } from '@wap-client/core'

import Video from '@/components/base/video'
import Icon from '@/components/base/icon'
import Fancybox from '@/components/base/fancybox/Fancybox'
import Anchor from '@/components/base/anchor/Anchor'
import Image from '@/components/base/image'

import { FestivalGalleryProps, GalleryItem, GalleryPhotoVideo } from './types'

const FestivalGallery: React.FunctionComponent<FestivalGalleryProps> = ({
  gallery = [],
}) => {
  const app = useApp()
  const width = useWidth()

  const [galleryFancy, setGalleryFancy] = useState<GalleryPhotoVideo[]>([])

  useEffect(() => {
    const fancyGallery: any = []

    gallery.forEach((item) => {
      item.photos.forEach((photo) => {
        fancyGallery.push(photo)
      })
    })

    setGalleryFancy(fancyGallery)
  }, [gallery])

  const galleryFancybox = () => {
    return galleryFancy.map((item, index) =>
      item.video ? (
        <Anchor
          data-fancybox="gallery"
          href={path.asset(app.environment, item.video?.src)}
          key={index}
          className="festival-photos-invisible"
        >
          <Image src={item.video.poster} alt="" />
        </Anchor>
      ) : (
        <Anchor
          data-fancybox="gallery"
          href={path.asset(app.environment, item.image?.src)}
          key={index}
          className="festival-photos-invisible"
        />
      )
    )
  }

  const renderGallery = (gallery: GalleryItem, galleryIndex: number) => {
    let photoIndex = galleryIndex * 5

    return (
      <SwiperSlide key={galleryIndex}>
        <div className="festival-gallery__photos">
          <Fancybox>
            {gallery.photos.map((photo, index) => {
              const currentIndex = photoIndex + index
              return renderPhoto(photo, currentIndex)
            })}
          </Fancybox>
        </div>
      </SwiperSlide>
    )
  }

  const renderPhoto = (photo: GalleryPhotoVideo, index: number) => {
    const videoSettings = {
      autoPlay: true,
      controls: false,
      loop: true,
      muted: true,
      playsInline: true,
      preload: 'none',
    }

    return (
      <div className="festival-gallery__photos__item" key={index}>
        <figure>
          {photo.video || photo.videoSmall ? (
            <Anchor data-fancybox-trigger="gallery" data-fancybox-index={index}>
              {photo.videoSmall ? (
                <Video {...videoSettings} {...photo.videoSmall} />
              ) : (
                <Video {...videoSettings} {...photo.video} />
              )}

              <Icon name="play" size={'large'} />
            </Anchor>
          ) : (
            <Anchor data-fancybox-trigger="gallery" data-fancybox-index={index}>
              <Image {...photo.thumbnail} alt={photo.thumbnail?.alt} />
            </Anchor>
          )}
        </figure>
      </div>
    )
  }

  return (
    <section className="festival-gallery">
      <div hidden>
        <Fancybox>{galleryFancybox()}</Fancybox>
      </div>

      <Swiper
        slidesPerView={1}
        freeMode={{
          enabled: true,
          sticky: false,
        }}
        loop={true}
        autoplay={{ delay: 5000 }}
        width={width < 1025 ? 910 : null}
        modules={[FreeMode, Autoplay]}
      >
        {gallery.map((gallery, index) => renderGallery(gallery, index))}
      </Swiper>
    </section>
  )
}

export default FestivalGallery
