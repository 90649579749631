/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import { path } from '@wap-client/core'
import { functions } from '@wap-client/core'
import { useApp } from '@wap-client/core'

import type { VideoProps } from './types'

const Video: React.ForwardRefRenderFunction<HTMLVideoElement, VideoProps> = (
  props,
  ref
) => {
  const attributes = { ...props, ...props.source }
  const {
    className,
    height = 'auto',
    sources = [],
    src,
    testId = 'video',
    width = '100%',
    ...rest
  } = attributes

  const classNames = functions.classnames('video', className)
  const app = useApp()

  return (
    <video
      ref={ref}
      className={classNames}
      controls
      height={height}
      width={width}
      data-testid={testId}
      src={path.asset(app.environment, src || '')}
      {...rest}
    >
      {sources.map((props, index) => {
        return (
          <source
            key={index}
            {...props}
            src={path.asset(app.environment, props.src || '')}
          />
        )
      })}
      Your browser does not support the video tag.
    </video>
  )
}

export default React.forwardRef(Video)
